// Generated by Framer (16060ad)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {zspjaDIAU: {hover: true}};

const cycleOrder = ["zspjaDIAU"];

const variantClassNames = {zspjaDIAU: "framer-v-1xymcgl"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "zspjaDIAU", image: RdGHUPMUn = {src: new URL("assets/512/3541ObOo0jukXOCxlnkFT69U.png", import.meta.url).href, srcSet: `${new URL("assets/512/3541ObOo0jukXOCxlnkFT69U.png", import.meta.url).href} 341w, ${new URL("assets/1024/3541ObOo0jukXOCxlnkFT69U.png", import.meta.url).href} 682w, ${new URL("assets/2048/3541ObOo0jukXOCxlnkFT69U.png", import.meta.url).href} 1365w, ${new URL("assets/3541ObOo0jukXOCxlnkFT69U.png", import.meta.url).href} 2000w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "zspjaDIAU", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-6APfy", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1xymcgl", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"zspjaDIAU"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"zspjaDIAU-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", intrinsicHeight: 1750, intrinsicWidth: 1400, pixelHeight: 1750, pixelWidth: 1400, ...toResponsiveImage(RdGHUPMUn)}} className={"framer-1gyc2io"} data-framer-name={"team_katja"} layoutDependency={layoutDependency} layoutId={"pdZJRMkSp"} transition={transition} {...addPropertyOverrides({"zspjaDIAU-hover": {background: {alt: "", fit: "fill", intrinsicHeight: 1750, intrinsicWidth: 1400, pixelHeight: 1750, pixelWidth: 1400, sizes: "500px", ...toResponsiveImage(RdGHUPMUn)}}}, baseVariant, gestureVariant)}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-6APfy [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6APfy .framer-1v1shji { display: block; }", ".framer-6APfy .framer-1xymcgl { height: 438px; overflow: hidden; position: relative; width: 350px; }", ".framer-6APfy .framer-1gyc2io { aspect-ratio: 0.8 / 1; bottom: var(--framer-aspect-ratio-supported, 0px); flex: none; left: calc(50.00000000000002% - 100% / 2); overflow: visible; position: absolute; top: 0px; width: 100%; }", ".framer-6APfy .framer-v-1xymcgl .framer-1xymcgl { cursor: pointer; }", ".framer-6APfy.framer-v-1xymcgl.hover .framer-1xymcgl { aspect-ratio: 0.7990867579908676 / 1; height: var(--framer-aspect-ratio-supported, 438px); }", ".framer-6APfy.framer-v-1xymcgl.hover .framer-1gyc2io { bottom: -93px; left: calc(50.00000000000002% - 500px / 2); top: -94px; width: var(--framer-aspect-ratio-supported, 500px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 438
 * @framerIntrinsicWidth 350
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Jn53jHDnR":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"RdGHUPMUn":"image"}
 */
const FramerWM4HMT5QR: React.ComponentType<Props> = withCSS(Component, css, "framer-6APfy") as typeof Component;
export default FramerWM4HMT5QR;

FramerWM4HMT5QR.displayName = "team-katja";

FramerWM4HMT5QR.defaultProps = {height: 438, width: 350};

addPropertyControls(FramerWM4HMT5QR, {RdGHUPMUn: {__defaultAssetReference: "data:framer/asset-reference,3541ObOo0jukXOCxlnkFT69U.png?originalFilename=image.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerWM4HMT5QR, [])